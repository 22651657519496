<template>
    <div>
        <vineyards-component/>
        <footer-component/>
    </div>
</template>

<script>
    import VineyardsComponent from "@/components/vineyards/VineyardsComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "Vineyards",
        title: "Viñedos | Turismo BC",
        components: {FooterComponent, VineyardsComponent}
    }
</script>

<style scoped>

</style>